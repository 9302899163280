import React from 'react'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  let navigate = useNavigate()
  return (
    sessionStorage.getItem('token') ? 
    <div>
      <section className="home">
        <div className="home1">
          <h1>Thank you for visiting this page. We will update this section soon.</h1>
        </div>
      </section>
    </div> : navigate('/login')
  )
}

export default Home