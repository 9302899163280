import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Categories = () => {
  const location = useLocation();
  let navigate = useNavigate()
  
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get('catg');
  const weightParams = queryParams.get('weight');
  const catgId = queryParams.get('catgId');
  const weights = weightParams.split(', ');

  return (
    sessionStorage.getItem('token') ? 
    <div>
        <section className="home">
            <div className="home1">
                <div className="row">
                    {weights.map((weight, index) => (
                      <div key={index} className="col-md-3">
                          <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                              <div className="card-body">
                                  <h5 className="card-title"><u>{ category }</u></h5>
                                  <p className="card-text">{ decodeURIComponent(weight) }</p>
                                  <Link to={{ pathname: "/participants", search: `?lowerWeight=${index > 0 ? decodeURIComponent(weights[index-1]) : 0}&upperWeight=${index === weights.length - 1 ? '200kg' : decodeURIComponent(weight)}&catgId=${catgId}`}} className="btn btn-outline-dark text-light">View</Link>
                              </div>
                          </div>
                      </div>
                    ))}
                </div>
            </div>
        </section>
    </div> : navigate('/login')
  )
}

export default Categories