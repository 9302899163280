import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { backendApiUrl } from '../config/config';

const Participants = () => {
    let navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const lowerWeight = queryParams.get('lowerWeight');
    const upperWeight = queryParams.get('upperWeight');
    const catgId = queryParams.get('catgId');
    const lweight = lowerWeight.replace(/\D/g, ''); // This removes all non-digit characters
    const uweight = upperWeight.replace(/\D/g, ''); // This removes all non-digit characters

    const [myDatas, setMyDatas] = useState([]);
    const [isError, setIsError] = useState([]);
    const [checkedParticipants, setCheckedParticipants] = useState([]);

    useEffect(() => {
      axios.get(`${backendApiUrl}participant/getParticipant?lowerLimit=${lweight}&upperLimit=${uweight}&catgId=${catgId}`)
      .then((res) => {
        setMyDatas(res.data.data);
      })
      .catch((error) => {
        setIsError(error.message);
      })
    }, [])

    const handleCheckboxChange = (participant) => {
        setCheckedParticipants(prevState => {
            if (prevState.includes(participant)) {
                return prevState.filter(p => p !== participant);
            } else {
                return [...prevState, participant];
            }
        });
    };

    const handlePreviewClick = () => {
        navigate('/preview', { state: { participants: checkedParticipants } });
        console.log(checkedParticipants);
    };
    
  return (
    sessionStorage.getItem('token') ? 
    <div className='home'>
        <section className='participant1'>
            <div className="row">
                <div className="col-md-8 participant1_1">
                    <table class="table">
                        <thead>
                            <tr>
                            <th></th>
                            <th scope="col">S No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Chest No.</th>
                            <th scope="col">DOB</th>
                            <th scope="col">Mobile No.</th>
                            <th scope="col">Hand</th>
                            <th scope="col">Actual Weight</th>
                            <th scope="col">District</th>
                            <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {myDatas.length > 0 ? myDatas.map((myData, index) => {
                                return (
                                    <tr key={index}>
                                        <td><div class="form-check">
                                        <input className="form-check-input checkbox" type="checkbox" value={myData.id} onChange={() => handleCheckboxChange(myData)} />
                                        <label class="form-check-label" for="flexCheckDefault">
                                        </label>
                                        </div></td>
                                        <th scope='row'>{index + 1}</th>
                                        <td>{myData.name}</td>
                                        <td>{myData.id}</td>
                                        <td>{myData.dob}</td>
                                        <td>{myData.mobileNo}</td>
                                        <td>{myData.hand}</td>
                                        <td>{myData.weight}</td>
                                        <td>{myData.district}</td>
                                        <td>{myData.status}</td>
                                    </tr>
                                )
                            }) : <tr>
                                    <th scope='row'></th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            }
                            
                        </tbody>
                    </table>
                </div>
                <div className="col-md-4 mt-4">
                    <button onClick={handlePreviewClick} type="button" class="btn btn-success">Preview</button>
                </div>
            </div>
        </section>
    </div> : navigate('/login')
  )
}

export default Participants