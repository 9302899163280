import React, { useState } from 'react'
import logo from '../assets/logo/GoldenArm_India.png'
import axios from 'axios';
import { backendApiUrl } from '../config/config';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    let navigate = useNavigate();
    const data = {
        username: "",
        password: ""
    }

    const [credentials, setCredentials] = useState([data]);

    const onChange = async (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await axios.post(`${backendApiUrl}auth/loginAdmin`, credentials);
        if(response.data.user.token) {
            sessionStorage.setItem('token', response.data.user.token);
            navigate('/')
        }else{
            alert(response.data.msg);
        }
    }
  return (
    !sessionStorage.getItem('token') ? 
    <div>
        <section className='home container-fluid' id='login1'>
            <div className="row">
                <div className="col-md-6" id='leftSection'>
                    <img src={logo} alt="goldenArmLogo" />
                </div>
                <div className="col-md-2"></div>
                <div className="col-md-4" id="rightSection">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3 text-start">
                            <label htmlFor="username" className="form-label"><b>Username:</b></label>
                            <input type="text" className="form-control" id="username" name="username" value={credentials.username} onChange={onChange} required />
                        </div>
                        <div className="mb-3 text-start">
                            <label htmlFor="password" className="form-label"><b>Password:</b></label>
                            <input type="password" className="form-control" id="password" name="password" value={credentials.password} onChange={onChange} required />
                        </div>
                        <button type="submit" className="btn btn-dark">Submit</button>
                    </form>
                </div>
            </div>
        </section>
    </div> : navigate('/login')
  )
}

export default Login