import React from 'react'
import preview from '../assets/preview/preview1.mp4';
import { useLocation } from 'react-router-dom';

const Preview = () => {
  const location = useLocation();
  const { participants } = location.state || { participants: [] }
  return (
    <div className='preview-container'>
        <section className='video-section'>
            <video className='videoTag' autoPlay loop muted>
                <source src={preview} type="video/mp4" />
            </video>
            <div className="participant-details">
              <div className="row">
                  {participants.length > 0 && (
                    <div className="col-md-5">
                      {/* <h2>Participant 1</h2> */}
                      <h2><b>Name: {participants[0].name}</b></h2>
                      <h2><b>Chest No.: {participants[0].id}, District: {participants[0].district}</b></h2>
                      <h2><b>Hand: {participants[0].hand}</b></h2>
                      <h2><b>Weight: {participants[0].weight} KG</b></h2>
                    </div>
                  )}
                <div className="col-md-2"></div>
                  {participants.length > 1 && (
                    <div className="col-md-5">
                      {/* <h2>Participant 2</h2> */}
                      <h2><b>Name: {participants[1].name}</b></h2>
                      <h2><b>Chest No.: {participants[1].id}, District: {participants[1].district}</b></h2>
                      <h2><b>Hand: {participants[1].hand}</b></h2>
                      <h2><b>Weight: {participants[1].weight}</b></h2>
                    </div>
                  )}
              </div>
            </div>
        </section>
    </div>
  )
}

export default Preview