import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import Registration from './components/Registration';
import Newsroom from './components/Newsroom';
import Gallery from './components/Gallery';
import Category from './components/Category';
import Categories from './components/Categories';
// import Fixture from './components/Fixture';
import Login from './components/Login';
import Participants from './components/Participants';
import Theme from './components/Theme';
import Preview from './components/Preview';
// import CustomMatch from './components/CustomMatch';

function App() {
  return (
    <>
    <BrowserRouter>
    <Navbar />
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/registration" element={<Registration />} />
          <Route exact path="/newsroom" element={<Newsroom />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/category" element={<Category />} />
          <Route exact path="/categories" element={<Categories />} />
          {/* <Route exact path="/fixture" element={<Fixture />} /> */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/participants" element={<Participants />} />
          <Route exact path="/theme" element={<Theme />} />
          {/* <Route exact path="/themes" element={<CustomMatch />} /> */}
          <Route exact path="/preview" element={<Preview />} />
        </Routes>
      </div>
    </BrowserRouter>
    </>
  );
}

export default App;
