import React, { useCallback, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../src/assets/logo/apple-touch-icon.png'
import logo1 from '../../src/assets/logo/GoldenArm_India.png'

const Navbar = () => {
    let navigate = useNavigate()

    const handleLogout = useCallback((e) => {
        sessionStorage.removeItem('token');
        navigate('/login'); // Add redirection after logout
      }, [navigate]);
    
    useEffect(() => {
    if (!sessionStorage.getItem('token')) {
        navigate('/login');
    }
    }, [navigate]);
  return (
    sessionStorage.getItem('token') ?  
    window.location.pathname === '/preview' ? "" :
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark" id="navbar">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/"><img src={logo1} id="headerLogo" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
                <ul className="navbar-nav mb-2 mb-lg-0 mt-0 pb-4">
                    <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/about">About</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/registration">Registration Form</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/newsroom">Newsroom</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/gallery">Gallery</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="/category">Participants</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link active" to="#" onClick={handleLogout}>Logout</Link>
                    </li>
                    {/* <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Dropdown
                    </a>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#">Action</a></li>
                        <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                    </li> */}
                    {/* <li className="nav-item">
                    <a className="nav-link disabled" aria-disabled="true">Disabled</a>
                    </li> */}
                </ul>
                {/* <form className="d-flex" role="search">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>
                </form> */}
                </div>
                <Link className="navbar-brand ms-auto" to="/"><img src={logo} id="headerLogo" /></Link>
            </div>
        </nav>
    </div> : ""
  )
}

export default Navbar