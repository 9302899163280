import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Category = () => {
    let navigate = useNavigate()
  return (
    sessionStorage.getItem('token') ? 
    <div>
        <section className="home">
            <div className="home1">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>JUNIORS - U15</u></h5>
                                <p class="card-text">BOYS - 57kg, 63kg, 78kg, +78kg</p>
                                <Link to={{ pathname: "/categories", search: "?catg=JUNIOR-U15 BOYS&weight=57kg, 63kg, 78kg, %2B78kg&catgId=1" }} className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>JUNIORS - U18</u></h5>
                                <p class="card-text">BOYS - 57kg, 63kg, 70kg, 78kg, 86kg, +86kg</p>
                                <Link to={{ pathname: "/categories", search: "?catg=JUNIOR-U18 BOYS&weight=57kg, 63kg, 70kg, 78kg, 86kg, %2B86kg&catgId=2" }} className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>JUNIORS - U21</u></h5>
                                <p class="card-text">BOYS - 63kg, 70kg, 78kg, 86kg, 95kg, +95kg</p>
                                <Link to={{ pathname: "/categories", search: "?catg=JUNIOR-U21 BOYS&weight=63kg, 70kg, 78kg, 86kg, 95kg, %2B95kg&catgId=3" }} href="#" className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>SENIORS - U21</u></h5>
                                <p class="card-text">MEN - 57kg, 63kg, 70kg, 78kg, 86kg, 95kg, 105kg, +105kg</p>
                                <Link to={{ pathname: "/categories", search: "?catg=SENIOR-U21 MEN&weight=57kg, 63kg, 70kg, 78kg, 86kg, 95kg, 105kg, %2B105kg&catgId=4" }} className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>MASTERS - 40+</u></h5>
                                <p class="card-text">MEN - 70kg, 78kg, 86kg, 95kg, 105kg, +105kg</p>
                                <Link to={{ pathname: "/categories", search: "?catg=MASTER-40 MEN&weight=70kg, 78kg, 86kg, 95kg, 105kg, %2B105kg&catgId=5" }} className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>SENIOR GRANDMASTER</u></h5>
                                <p class="card-text">MEN - (60+) MEN - 70kg, 78kg, 86kg, 95kg, 105kg, +105kg</p>
                                <Link to={{ pathname: "/categories", search: "?catg=SENIOR GRANDMASTER-60%2B MEN&weight=70kg, 78kg, 86kg, 95kg, 105kg, %2B105kg&catgId=6" }} className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>DISABLED CLASSES (PHYSICAL IMPAIRED STANDING)</u></h5>
                                <p class="card-text">MEN - 65kg, 80kg, 95kg, +95kg</p>
                                <Link to={{ pathname: "/categories", search: "?catg=DISABLED CLASSES (PHYSICAL IMPAIRED STANDING) MEN&weight=65kg, 80kg, 95kg, %2B95kg&catgId=7" }} className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>PHYSICAL IMPAIRED UNDER-23 (STANDING)</u></h5>
                                <p class="card-text">MEN - OPEN</p>
                                <Link to={{ pathname: "/categories", search: "?catg=PHYSICAL IMPAIRED UNDER-23 (STANDING) MEN&weight=OPEN&catgId=8" }} className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>VISUAL STANDING MEN</u></h5>
                                <p class="card-text">MEN - 86kg, +86kg</p>
                                <Link to={{ pathname: "/categories", search: "?catg=VISUAL STANDING MEN&weight=86kg, %2B86kg&catgId=9" }} className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card text-light mx-2 my-2" style={{ width: "18rem", backgroundColor: "transparent", border: "solid 5px white" }}>
                            <div className="card-body">
                                <h5 className="card-title"><u>HEARING STANDING</u></h5>
                                <p class="card-text">MEN - 70kg, 95kg, +95kg</p>
                                <Link to={{ pathname: "/categories", search: "?catg=HEARING STANDING MEN&weight=70kg, 95kg, %2B95kg&catgId=10" }} className="btn btn-outline-dark text-light">View</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div> : navigate('/login')
  )
}

export default Category