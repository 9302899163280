import React, { useState } from 'react'
import { backendApiUrl } from '../config/config';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const Registration = () => {
    let navigate = useNavigate()

    const data = {
        name : "",
        dob : "",
        mobileNo : "",
        category : "",
        hand : "",
        weight : "",
        designation : "",
        district : ""
    }

    const [credentials, setCredentials] = useState([data]);

    const onChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await axios.post(`${backendApiUrl}participant/addParticipant`, credentials);
        if(response.data) {
            alert("Registration Successful...!!!");
            window.location.reload();
        }
    }

  return (
    sessionStorage.getItem('token') ? 
    <div>
        <section className="home">
            <div className="registration1">
                <div className="row text-start">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                    <form className='text-dark formDecoration' onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label"><b>Name:</b></label>
                                <input type="text" className="form-control" id="name" name="name" placeholder='Full Name' value={credentials.name} onChange={onChange} required/>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="mobileNo" className="form-label"><b>Mobile:</b></label>
                                <input type="tel" className="form-control" id="mobileNo" name="mobileNo" placeholder='+91 1234567890' value={credentials.mobileNo} onChange={onChange} required/>
                            </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="dob" className="form-label"><b>DOB:</b></label>
                                <input type="date" className="form-control" id="dob" name="dob" value={credentials.dob} onChange={onChange} required/>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="category" className="form-label"><b>Category:</b></label>
                                <select className="form-select" id="category" name="category" value={credentials.category} onChange={onChange} aria-label="Default select example">
                                    <option defaultValue>Open this select menu</option>
                                    <option value="1">JUNIORS - U15</option>
                                    <option value="2">JUNIORS - U18</option>
                                    <option value="3">JUNIORS - U21</option>
                                    <option value="4">SENIORS - U21</option>
                                    <option value="5">MASTERS - 40+</option>
                                    <option value="6">SENIOR GRANDMASTER</option>
                                    <option value="7">DISABLED CLASSES (PHYSICAL IMPAIRED STANDING)</option>
                                    <option value="8">PHYSICAL IMPAIRED UNDER-23 (STANDING)</option>
                                    <option value="9">VISUAL STANDING MEN</option>
                                    <option value="10">HEARING STANDING</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="hand" className="form-label"><b>Hand:</b></label>
                                <select className="form-select" id="hand" name="hand" value={credentials.hand} onChange={onChange} aria-label="Default select example">
                                    <option defaultValue>Open this select menu</option>
                                    <option value="Left">Left</option>
                                    <option value="Right">Right</option>
                                </select>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="weight" className="form-label"><b>Weight:</b></label>
                                <input type="text" className="form-control" id="weight" name="weight" value={credentials.weight} onChange={onChange} required/>
                                <div id="weightHelp" class="form-text" style={{ color: "yellow" }}>Please don't add 'kg' after weight</div>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="designation" className="form-label"><b>Designation:</b></label>
                                <input type="text" className="form-control" id="designation" name="designation" value={credentials.designation} onChange={onChange} required/>
                            </div>
                            </div>
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="state" className="form-label"><b>District:</b></label>
                                <select className="form-select" id="district" name="district" value={credentials.district} onChange={onChange} aria-label="Default select example">
                                    <option defaultValue>Open this select menu</option>
                                    <option value="Balod">Balod</option>
                                    <option value="Balodabazar">Balodabazar</option>
                                    <option value="Balrampur">Balrampur</option>
                                    <option value="Bastar">Bastar</option>
                                    <option value="Bemetara">Bemetara</option>
                                    <option value="Bijapur">Bijapur</option>
                                    <option value="Bilaspur">Bilaspur</option>
                                    <option value="Dantewada">Dantewada</option>
                                    <option value="Dhamtari">Dhamtari</option>
                                    <option value="Durg">Durg</option>
                                    <option value="Gariaband">Gariaband</option>
                                    <option value="Gaurela-Pendra-Marwahi">Gaurela-Pendra-Marwahi</option>
                                    <option value="Janjgir-Champa">Janjgir-Champa</option>
                                    <option value="Jashpur">Jashpur</option>
                                    <option value="Kanker">Kanker</option>
                                    <option value="Kawardha">Kawardha</option>
                                    <option value="Kondagaon">Kondagaon</option>
                                    <option value="Korba">Korba</option>
                                    <option value="Korea">Korea</option>
                                    <option value="Mahasamund">Mahasamund</option>
                                    <option value="Mungeli">Mungeli</option>
                                    <option value="Narayanpur">Narayanpur</option>
                                    <option value="Raigarh">Raigarh</option>
                                    <option value="Raipur">Raipur</option>
                                    <option value="Rajnandgaon">Rajnandgaon</option>
                                    <option value="Sukma">Sukma</option>
                                    <option value="Surajpur">Surajpur</option>
                                    <option value="Surguja">Surguja</option>
                                </select>
                            </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-8">
                                <label htmlFor="photo" className="form-label"><b>Choose Photo:</b></label>
                                <div class="input-group mb-3">
                                    <input type="file" class="form-control" id="inputGroupFile02" />
                                    <label class="input-group-text" for="inputGroupFile02">Upload</label>
                                </div>  
                            </div>
                        </div> */}
                        <button type="submit" className="btn btn-outline-dark"><b>Register</b></button>
                    </form>
                    </div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        </section>
    </div> : navigate('/login')
  )
}

export default Registration